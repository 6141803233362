//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalAddListM from "@/components/modals/ModalAddListM.vue";
import ModalUpdateListM from "@/components/modals/ModalUpdateListM.vue";
import ModalRemoveListM from "@/components/modals/ModalRemoveListM.vue";
import ModalListmImport from "@/components/modals/ModalListmImport.vue";
import MainTitle from "@/components/MainTitle.vue";
import ListMsTable from "@/components/ListM/ListMsTable.vue";
import ModalLimitReachedTemplate from "@/components/modals/access/ModalLimitReachedTemplate.vue";
import ListMsWrapper from "@/components/ListM/Wrapper.vue";
export default {
  meta: {
    title: "Мои мониторинги"
  },
  data() {
    return {
      refreshKey: 0,
      hasItems: this.$store.state.user.user ? null : false,
      listMsTotalRow: null,
      hasItemsLoaded: false
    };
  },
  async created() {
    const result = await this.$store.dispatch("listM/getListMs", {
      page: 1
    });
    this.hasItems = !!result.items.length;
    this.hasItemsLoaded = true;
  },
  methods: {
    onEdit({
      item,
      context
    }) {
      this.$refs.modalUpdateListM.open({
        item,
        onComplete() {
          context.refresh();
        }
      });
    },
    onRemove({
      item,
      context
    }) {
      this.$refs.modalRemoveListM.open({
        item,
        onComplete() {
          context.refresh();
        }
      });
    },
    onAdded(item) {
      this.refreshKey++;
      this.hasItems = true;
      setTimeout(() => {
        this.$refs.modalListmImport.open({
          item,
          freshList: true
        });
      }, 100);
    },
    onCreateList() {
      this.$access.hasOrThrow("listMs");
      const monitorsLimit = this.$access.getLimit("monitors");

      //// alert('src/views/ListMs.vue: monitorsLimit='+monitorsLimit)
      //// alert('src/views/ListMs.vue: this.ListMsTotalRow='+this.ListMsTotalRow)

      if (this.ListMsTotalRow >= monitorsLimit) {
        this.$modal.open(() => import("@/components/modals/access/ModalLimitReachedTemplate"), {
          alert: "Исчерпан лимит мониторингов",
          title: "Исчерпан лимит мониторингов на Вашем тарифе"
        });
      } else {
        this.$refs.modalAddListM.open();
      }
    }
    /*
            // onListMsTotalRow(total) {
             //     alert('111: total='+total)
             //     this.listMsTotalRow = total
            // }
            */
  },

  computed: {
    tableKey() {
      return this.refreshKey;
    }
  },
  components: {
    ModalAddListM,
    ModalUpdateListM,
    ModalRemoveListM,
    ModalListmImport,
    MainTitle,
    ListMsTable,
    ModalLimitReachedTemplate,
    ListMsWrapper
  }
};