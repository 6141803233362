/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./NoListMs.vue?vue&type=template&id=4004fa98&scoped=true&"
import script from "./NoListMs.vue?vue&type=script&lang=js&"
export * from "./NoListMs.vue?vue&type=script&lang=js&"
import style0 from "./NoListMs.vue?vue&type=style&index=0&id=4004fa98&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4004fa98",
  null
  
)

export default component.exports